<template>
  <div class="manage-project-booking__rejected">
    <div>
      <rejected-booking-table
        :getBookingsAPI="bookingAPI.getBookings"
        :cancelBookingAPI="bookingAPI.cancelBooking"
        detailRouteName="ManageProjectBookingDetail"
      ></rejected-booking-table>
    </div>
  </div>
</template>

<script>
import RejectedBookingTable from "@/components/GlobalComponents/Shared/ProjectSales/Booking/RejectedBookingTable";
import { projectBooking as bookingAPI } from "@/api";

export default {
  components: { RejectedBookingTable },
  mixins: [],
  props: {},
  data: function () {
    return {
      bookingAPI
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>
